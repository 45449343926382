<template>
  <div class="Overview">
    <h2>业务总览</h2>
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="top">
          <div class="title">塔式起重机监控安装数量</div>
          <div class="value">{{TowerTotals}}台</div>
        </div>
        <div class="blankBottom"></div>
      </el-col>
      <el-col :span="6">
        <div class="top">
          <div class="title">塔式起重机监控在线率</div>
          <div class="value">{{onlineRate}}%</div>
        </div>
        <div class="bottom">
          <el-progress
            :show-text="false"
            :stroke-width="20"
            :percentage="onlineRate"
            status="success"
          ></el-progress>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="top">
          <div class="title">累计塔式起重机预警及报警次数</div>
          <div class="value">{{alert + earlyAlert}}次</div>
        </div>
        <div class="flexBottom">
          <span>预警次数 {{earlyAlert}} 次</span>
          <span>报警次数 {{alert}} 次</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="top">
          <div class="title">累计塔式起重机违章次数</div>
          <div class="value">{{brar}}次</div>
        </div>
        <div class="blankBottom"></div>
      </el-col>
    </el-row>
    <el-table :data="listData" style="margin-top: 20px">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="deviceStateStr" label="设备状态">
        <template slot-scope="scope">
          <div class="deviceStateWrap">
            <div :class="'deviceStateIcon' + scope.row.deviceState"></div>
            <span :class="'deviceState' + scope.row.deviceState">{{ scope.row.deviceStateStr }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="titleRecordNumber" label="产权备案号"> </el-table-column>
      <el-table-column prop="deviceSn" label="设备SN码"> </el-table-column>
      <el-table-column prop="deviceName" label="设备名称"> </el-table-column>
      <el-table-column prop="manufacturerName" label="设备厂商"> </el-table-column>
      <el-table-column prop="monthAlert" label="本月报警次数"> </el-table-column>
      <el-table-column prop="monthBrar" label="本月违章次数"> </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next"
      :total="totals"
      :page-size="pageSize"
      :current-page.sync="pageNum"
      @current-change="onGetListData"
      @size-change="handleSizeChange"
      :page-sizes="[10, 50, 100, 200, 500]"
    >
    </el-pagination>
  </div>
</template>

<style lang="less" scoped>
.Overview {
  padding: 20px;
  .top {
    padding: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title {
      display: block;
      font-size: 14px;
      color: #ccc;
    }
    .value {
      display: block;
      font-size: 30px;
      color: #000;
      font-weight: 400;
    }
  }
  .bottom {
    padding: 20px;
    background-color: #fff;
  }
  .blankBottom {
    height: 60px;
    background-color: #fff;
  }
  .flexBottom {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    background-color: #fff;
  }

  .deviceStateWrap {
    display: flex;
    vertical-align: middle;
  }
  .deviceState1 {
    display: inline-block;
    color: #f00;
    font-size: 14px;
    line-height: 14px;
  }
  .deviceState0 {
    display: inline-block;
    color: #01b91c;
    font-size: 14px;
    line-height: 14px;
  }
  .deviceStateIcon1 {
    display: inline-block;
    width:14px;
    height: 14px;
    background-color: #f00;
    border-radius: 50%;
    line-height: 14px;
    margin-right: 5px;
  }
  .deviceStateIcon0 {
    display: inline-block;
    width:14px;
    height: 14px;
    background-color: #01b91c;
    border-radius: 50%;
    line-height: 14px;
    margin-right: 5px;
  }
}
</style>

<script>
export default {
  name: "Overview",
  components: {},
  data() {
    return {
      TowerTotals: 0,
      onlineRate: 100,
      alert: 0,
      earlyAlert: 0,
      brar: 0,

      pageSize: 10,
      pageNum: 1,
      totals: 0,
      listData: [],
      corpCode: "",
      corpName: "",
      corpType: "",
      legalMan: "",
      typeList: [],
    };
  },
  computed: {},
  mounted() {
    this.onGetListData()
    this.onGetTowerAlertAll()
  },
  methods: {
    goto: function(path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取统计数据
    onGetTowerAlertAll: function() {
      var that = this;
      var data = {
        projectId: localStorage.getItem("projectId"),
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/OverView/TowerAlertAll",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            
            that.TowerTotals = parseFloat(res.data.TowerTotals)
            that.onlineRate = parseFloat(res.data.onlineRate)
            that.alert = parseFloat(res.data.AlerBrar.alert)
            that.earlyAlert = parseFloat(res.data.AlerBrar.earlyAlert)
            that.brar = parseFloat(res.data.AlerBrar.brar)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取主列表数据
    onGetListData: function() {
      var that = this;
      var data = {
        status: 0,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        projectId: localStorage.getItem("projectId"),
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/OverView/towerDevice",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            console.log('onGetListData',  res);
            that.listData = res.data.dataList;
            that.totals = res.data.totals;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换分页大小
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.onGetListData();
    },
  },
};
</script>
